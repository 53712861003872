/* eslint-disable */
import {
  CloseIcon,
  Heading,
  IconButton,
  ModalBody,
  ModalContainer,
  ModalTitle,
  useMatchBreakpoints,
  Text,
} from '@noahswap/uikit'
import { useWeb3React } from '@noahswap/wagmi'
import { useTranslation } from '@noahswap/localization'
import styled from 'styled-components'
import { DoubleCurrencyLogo } from 'views/Info/components/CurrencyLogo'

export const ModalHeader = styled.div<{ background?: string }>`
  align-items: center;
  background: transparent;
  display: flex;
  padding: 12px 24px;
`

const SelectBtn = styled.div`
  display: flex;
  padding: 19px;
  font-size: 20px;
  border: 1px solid #eeeeee;
  border-radius: 6px;
  margin-bottom: 10px;
  cursor: pointer;

  &.active {
    border-color: #000;
    background: #ffe169;
  }
`

const VoteModal: React.FC<React.PropsWithChildren<any>> = ({ onDismiss, symbolData, handleGoSelect }) => {
  const { isMobile } = useMatchBreakpoints()
  const { t } = useTranslation()
  // console.log('arr', symbolData)
  const handleGo = async () => {
    // 告诉父组件要执行handleGoSelect事件了
    onDismiss()
    handleGoSelect(symbolData)
  }
  return (
    <ModalContainer
      title={t('noah182')}
      $minWidth={isMobile ? '100%' : '418px'}
      $minHeight={'auto'}
      style={{ width: '300px' }}
    >
      <ModalHeader>
        <ModalTitle>
          <img src="/images/small-img/warning.png" alt="" width={30} />
          <Heading>&nbsp;{t('noah484')}</Heading>
        </ModalTitle>
        <IconButton variant="text" onClick={onDismiss}>
          <CloseIcon width="24px" color="#000" />
        </IconButton>
      </ModalHeader>
      <ModalBody p="0 24px 24px 24px" width="100%">
        <div
          style={{
            marginTop: '0px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <img src={symbolData.tokenInfo.logo} alt="" width={60} />
            <div style={{ margin: '5px 0', fontSize: '18px', fontWeight: 'bold' }}>{symbolData.symbol}</div>
            <div style={{ fontSize: '14px', color: '#85868A' }}>{symbolData.name}&nbsp;by Multichain</div>
          </div>
          <div style={{ marginTop: '20px', lineHeight: '18px', padding: '0 20px', fontSize: '14px' }}>
            {t('noah481')}
          </div>
        </div>
        {/* 我知道了 */}
        <div
          style={{
            // padding: '15px 0',
            height: '48px',
            lineHeight: '45px',
            background: '#FAD436',
            border: '1px solid #000000',
            borderRadius: '6px',
            textAlign: 'center',
            fontSize: '16px',
            fontWeight: '600',
            marginTop: '30px',
            cursor: 'pointer',
          }}
          onClick={() => {
            handleGo()
          }}
        >
          {t('noah483')}
        </div>
      </ModalBody>
    </ModalContainer>
  )
}

export default VoteModal
