/* eslint-disable */
import { useRef, RefObject, useCallback, useState, useMemo } from 'react'
import { Token } from '@noahswap/sdk'
import { Text, Button, CloseIcon, IconButton, LinkExternal, Input, Link } from '@noahswap/uikit'
import styled from 'styled-components'
import Row, { RowBetween, RowFixed } from 'components/Layout/Row'
import { useToken } from 'hooks/Tokens'
import { useRemoveUserAddedToken } from 'state/user/hooks'
import useUserAddedTokens from 'state/user/hooks/useUserAddedTokens'
import { CurrencyLogo } from 'components/Logo'
import { getBlockExploreLink, isAddress } from 'utils'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useTranslation } from '@noahswap/localization'
import Column, { AutoColumn } from '../Layout/Column'
import ImportRow from './ImportRow'
import { CurrencyModalView } from './types'

const Wrapper = styled.div`
  width: 100%;
  height: calc(100% - 60px);
  position: relative;
  padding: 24px 2px 70px;
`

const ClearCom = styled.div`
  /* position: absolute;
  bottom: 0; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-top: 1px solid #f2f2f2; */
`
export default function ManageTokens({
  setModalView,
  setImportToken,
  listData,
}: {
  setModalView: (view: CurrencyModalView) => void
  setImportToken: (token: Token) => void
  listData: any
}) {
  const { chainId } = useActiveWeb3React()

  const { t } = useTranslation()

  const [searchQuery, setSearchQuery] = useState<string>('')

  // manage focus on modal show
  const inputRef = useRef<HTMLInputElement>()
  const handleInput = useCallback((event) => {
    const input = event.target.value
    const checksummedInput = isAddress(input)
    setSearchQuery(checksummedInput || input)
  }, [])

  // if they input an address, use it
  const searchToken = useToken(searchQuery, listData)
  // console.log('ManageTokens', '执行了useToken')

  // all tokens for local list
  const userAddedTokens: Token[] = useUserAddedTokens()
  const removeToken = useRemoveUserAddedToken()

  const handleRemoveAll = useCallback(() => {
    if (chainId && userAddedTokens) {
      userAddedTokens.forEach((token) => {
        return removeToken(chainId, token.address)
      })
    }
  }, [removeToken, userAddedTokens, chainId])

  const tokenList = useMemo(() => {
    return (
      chainId &&
      userAddedTokens.map((token) => (
        <RowBetween key={token.address} width="100%">
          <RowFixed>
            <CurrencyLogo currency={token} size="24px" />
            <Link
              external
              href={getBlockExploreLink(token.address, 'address', chainId)}
              color="#000"
              ml="10px"
              style={{ fontWeight: 600, fontSize: '16px' }}
            >
              {token.symbol}
            </Link>
          </RowFixed>
          <RowFixed>
            <IconButton variant="text" onClick={() => removeToken(chainId, token.address)}>
              {/* <CloseIcon /> */}
              <img src="/images/small-img/icon1.png" width={20} alt="" />
            </IconButton>
            {/* <LinkExternal href={getBlockExploreLink(token.address, 'address', chainId)} /> */}
            <Link external href={getBlockExploreLink(token.address, 'address', chainId)} ml="10px">
              <img src="/images/small-img/icon2.png" width={20} alt="" />
            </Link>
          </RowFixed>
        </RowBetween>
      ))
    )
  }, [userAddedTokens, chainId, removeToken])

  const isAddressValid = searchQuery === '' || isAddress(searchQuery)

  return (
    <Wrapper style={{ paddingTop: 0 }}>
      <Column style={{ width: '100%', flex: '1 1', padding: '0 15px' }}>
        <AutoColumn gap="14px">
          <Row style={{ border: '1px solid #F6F6F6', paddingLeft: '10px', borderRadius: '10px' }}>
            <img src="/images/small-img/icon3.png" width={20} alt="" />
            <Input
              id="token-search-input"
              scale="lg"
              placeholder="0x0000"
              value={searchQuery}
              autoComplete="off"
              ref={inputRef as RefObject<HTMLInputElement>}
              onChange={handleInput}
              isWarning={!isAddressValid}
              style={{ paddingLeft: '0', marginLeft: '10px' }}
            />
          </Row>
          {!isAddressValid && (
            <Text color="red" fontSize="12px" style={{ marginTop: '-20px', paddingLeft: '0' }}>
              {t('noah35')}
            </Text>
          )}
          {searchToken && (
            <ImportRow
              token={searchToken}
              showImportView={() => setModalView(CurrencyModalView.importToken)}
              setImportToken={setImportToken}
              style={{ height: 'fit-content', background: '#F6F6F7', borderRadius: '5px' }}
            />
          )}
        </AutoColumn>
        <ClearCom>
          <Text color="#000" fontSize="12px" style={{ marginTop: '10px' }}>
            {userAddedTokens?.length} {userAddedTokens.length === 1 ? t('noah29') : t('noah29')}
          </Text>
          {userAddedTokens.length > 0 && (
            <Button
              variant="text"
              onClick={handleRemoveAll}
              style={{
                fontSize: '12px',
                color: '#306CCE',
                padding: '5px 0',
              }}
            >
              {t('noah30')}
            </Button>
          )}
        </ClearCom>
        {tokenList}
      </Column>
    </Wrapper>
  )
}
