/* eslint-disable no-restricted-syntax */
/* eslint-disable */
import { Currency, Token } from '@noahswap/sdk'
import { Box, Flex, Input, Text, useMatchBreakpoints } from '@noahswap/uikit'
import { KeyboardEvent, RefObject, useCallback, useMemo, useRef, useState, useEffect } from 'react'
import { useTranslation } from '@noahswap/localization'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useDebounce from 'hooks/useDebounce'
import useNativeCurrency from 'hooks/useNativeCurrency'
import { FixedSizeList } from 'react-window'
import { useAllLists, useInactiveListUrls } from 'state/lists/hooks'
import { TagInfo, WrappedTokenInfo } from '@noahswap/tokens'
import { useAudioModeManager } from 'state/user/hooks'
import { useCommonTokens } from 'hooks/useCommonTokens'
import { useAllTokens, useIsUserAddedToken, useToken } from '../../hooks/Tokens'
import { isAddress } from '../../utils'
import Column, { AutoColumn } from '../Layout/Column'
import Row from '../Layout/Row'
import CommonBases from './CommonBases'
import CurrencyList from './CurrencyList'
import { createFilterToken, useSortedTokensByQuery } from './filtering'
import useTokenComparator from './sorting'
import { getSwapSound } from './swapSound'

import ImportRow from './ImportRow'
import useUserAddedTokens, { userAddedTokenSelector } from '../../state/user/hooks/useUserAddedTokens'
interface CurrencySearchProps {
  selectedCurrency?: Currency | null
  onCurrencySelect: (currency: Currency) => void
  otherSelectedCurrency?: Currency | null
  showCommonBases?: boolean
  commonBasesType?: string
  showImportView: () => void
  setImportToken: (token: Token) => void
  height?: number
  listData?: any
}

// 选择搜索框推荐列表
function useSearchInactiveTokenLists(search: string | undefined, minResults = 10): WrappedTokenInfo[] {
  const lists = useAllLists()
  const inactiveUrls = useInactiveListUrls()
  const { chainId } = useActiveWeb3React()
  const activeTokens = useAllTokens()

  return useMemo(() => {
    const filterToken = createFilterToken(search)
    const exactMatches: WrappedTokenInfo[] = []
    const rest: WrappedTokenInfo[] = []
    const addressSet: { [address: string]: true } = {}
    const trimmedSearchQuery = search.toLowerCase().trim()
    for (const url of inactiveUrls) {
      const list = lists[url].current
      // eslint-disable-next-line no-continue
      if (!list) continue
      for (const tokenInfo of list.tokens) {
        if (
          tokenInfo.chainId === chainId &&
          !(tokenInfo.address in activeTokens) &&
          !addressSet[tokenInfo.address] &&
          filterToken(tokenInfo)
        ) {
          const tags: TagInfo[] =
            tokenInfo.tags
              ?.map((tagId) => {
                if (!list.tags?.[tagId]) return undefined
                return { ...list.tags[tagId], id: tagId }
              })
              ?.filter((x): x is TagInfo => Boolean(x)) ?? []
          const wrapped: WrappedTokenInfo = new WrappedTokenInfo(tokenInfo, tags)
          addressSet[wrapped.address] = true
          if (
            tokenInfo.name?.toLowerCase() === trimmedSearchQuery ||
            tokenInfo.symbol?.toLowerCase() === trimmedSearchQuery
          ) {
            exactMatches.push(wrapped)
          } else {
            rest.push(wrapped)
          }
        }
      }
    }
    return [...exactMatches, ...rest].slice(0, minResults)
  }, [activeTokens, chainId, inactiveUrls, lists, minResults, search])
}

function CurrencySearch({
  selectedCurrency,
  onCurrencySelect,
  otherSelectedCurrency,
  showCommonBases,
  commonBasesType,
  showImportView,
  setImportToken,
  height,
  listData,
}: CurrencySearchProps) {
  const { t } = useTranslation()
  const { chainId } = useActiveWeb3React()

  // refs for fixed size lists
  const fixedList = useRef<FixedSizeList>()

  const [searchQuery, setSearchQuery] = useState<string>('')
  const debouncedQuery = useDebounce(searchQuery, 200)

  const [invertSearchOrder] = useState<boolean>(false)

  // const allTokens = useAllTokens()
  // const allTokens = useCommonTokens()
  const userAddedTokens = useUserAddedTokens()
  const allTokens = { ...listData, ...userAddedTokens }
  // console.log('CurrencySearch.tsx', '执行了getCoinList')
  // console.log(debouncedQuery, 'debouncedQuery')
  // console.log('allTokens', allTokens)

  // if they input an address, use it
  const searchToken = useToken(debouncedQuery, listData)
  // console.log('ManageTokens', '执行了useToken')
  const searchTokenIsAdded = useIsUserAddedToken(searchToken)

  const { isMobile } = useMatchBreakpoints()
  const [audioPlay] = useAudioModeManager()

  const native = useNativeCurrency()

  const showEOS: boolean = useMemo(() => {
    const s = debouncedQuery.toLowerCase().trim()
    return native && native.symbol?.toLowerCase?.()?.indexOf(s) !== -1
  }, [debouncedQuery, native])

  const filteredTokens: any = useMemo(() => {
    const filterToken: any = createFilterToken(debouncedQuery)
    if (!debouncedQuery) {
      return (
        Object.values(allTokens)
          .filter(filterToken)
          // @ts-ignore
          ?.filter((v) => (v?.tokenInfo && v?.tokenInfo?.recommend) || !v?.tokenInfo)
      )
    }
    return Object.values(allTokens).filter(filterToken)
  }, [allTokens, debouncedQuery])

  const filteredQueryTokens = useSortedTokensByQuery(filteredTokens, debouncedQuery)

  const tokenComparator = useTokenComparator(invertSearchOrder)

  const filteredSortedTokens: Token[] = useMemo(() => {
    return [...filteredQueryTokens].sort(tokenComparator)
  }, [filteredQueryTokens, tokenComparator])

  const handleCurrencySelect = useCallback(
    (currency: Currency) => {
      onCurrencySelect(currency)
      if (audioPlay) {
        getSwapSound().play()
      }
    },
    [audioPlay, onCurrencySelect],
  )

  // manage focus on modal show
  const inputRef = useRef<HTMLInputElement>()

  useEffect(() => {
    if (!isMobile) inputRef.current.focus()
  }, [isMobile])

  const handleInput = useCallback((event) => {
    const input = event.target.value
    const checksummedInput = isAddress(input)
    setSearchQuery(checksummedInput || input)
    fixedList.current?.scrollTo(0)
  }, [])

  const handleEnter = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        const s = debouncedQuery.toLowerCase().trim()
        // TODO: FIXME
        if (s === 'eos') {
          handleCurrencySelect(native)
        } else if (filteredSortedTokens.length > 0) {
          if (
            filteredSortedTokens[0].symbol?.toLowerCase() === debouncedQuery.trim().toLowerCase() ||
            filteredSortedTokens.length === 1
          ) {
            handleCurrencySelect(filteredSortedTokens[0])
          }
        }
      }
    },
    [debouncedQuery, filteredSortedTokens, handleCurrencySelect, native],
  )

  // if no results on main list, show option to expand into inactive
  const filteredInactiveTokens = useSearchInactiveTokenLists(debouncedQuery)

  const hasFilteredInactiveTokens = Boolean(filteredInactiveTokens?.length)

  const getCurrencyListRows = useCallback(() => {
    // 注释这段代码, 会影响输入合约搜索的结果
    // 导入有数据
    // if (searchToken && !searchTokenIsAdded && !hasFilteredInactiveTokens) {
    //   return (
    //     <Column style={{ padding: '20px 0', height: '100%' }}>
    //       {/* 搜索自定义的合约地址 */}
    //       <ImportRow token={searchToken} showImportView={showImportView} setImportToken={setImportToken} />
    //     </Column>
    //   )
    // }
    // console.log(showCommonBases, 'showCommonBases')
    // console.log(height, 'height')
    // console.log('filteredSortedTokens', filteredSortedTokens)
    // console.log('hasFilteredInactiveTokens', hasFilteredInactiveTokens)

    return Boolean(filteredSortedTokens?.length) || hasFilteredInactiveTokens ? (
      <Box margin="24px -24px" height={isMobile ? (showCommonBases ? 280 : height ? height + 80 : 350) : 290}>
        <CurrencyList
          height={isMobile ? (showCommonBases ? 280 : height ? height + 80 : 350) : 290}
          showEOS={showEOS}
          currencies={filteredSortedTokens}
          inactiveCurrencies={filteredInactiveTokens}
          breakIndex={
            Boolean(filteredInactiveTokens?.length) && filteredSortedTokens ? filteredSortedTokens.length : undefined
          }
          onCurrencySelect={handleCurrencySelect}
          otherCurrency={otherSelectedCurrency}
          selectedCurrency={selectedCurrency}
          fixedListRef={fixedList}
          showImportView={showImportView}
          setImportToken={setImportToken}
        />
      </Box>
    ) : (
      // <Column style={{ padding: '20px', height: '100%' }}>
      //   <Text color="textSubtle" textAlign="center" mb="20px">
      //     {t('noah119')}
      //   </Text>
      //   </Column>
      <Flex justifyContent="center" alignItems="center" flexDirection="column" style={{ height: '240px' }}>
        <img src="/images/small-img/noData.png" alt="" width="64px" style={{ marginBottom: '10px' }} />
        <Text color="textSubtle" textAlign="center">
          {t('noah119')}
        </Text>
      </Flex>
    )
  }, [
    filteredInactiveTokens,
    filteredSortedTokens,
    handleCurrencySelect,
    hasFilteredInactiveTokens,
    otherSelectedCurrency,
    searchToken,
    searchTokenIsAdded,
    selectedCurrency,
    setImportToken,
    showEOS,
    showImportView,
    t,
    showCommonBases,
    isMobile,
    height,
  ])

  return (
    <>
      <AutoColumn gap="4px">
        <Row style={{ border: '1px solid #F6F6F6', paddingLeft: '10px', borderRadius: '10px' }}>
          <img src="/images/small-img/icon3.png" width={20} alt="" />
          <Input
            id="token-search-input"
            placeholder={t('noah189')}
            scale="lg"
            autoComplete="off"
            value={searchQuery}
            ref={inputRef as RefObject<HTMLInputElement>}
            onChange={handleInput}
            onKeyDown={handleEnter}
            style={{ fontWeight: 400 }}
          />
        </Row>
        <div style={{ borderBottom: '1px solid rgb(246, 246, 246)', paddingBottom: '10px' }}>
          {showCommonBases && (
            <CommonBases
              chainId={chainId}
              onSelect={handleCurrencySelect}
              selectedCurrency={selectedCurrency}
              commonBasesType={commonBasesType}
            />
          )}
        </div>
      </AutoColumn>
      {getCurrencyListRows()}
    </>
  )
}

export default CurrencySearch
