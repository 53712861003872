import { useState } from 'react'
import { Token, Currency } from '@noahswap/sdk'
import { Button, Text, ErrorIcon, Flex, Message, Checkbox, Link, Tag, Grid } from '@noahswap/uikit'
import { AutoColumn } from 'components/Layout/Column'
import { useAddUserToken } from 'state/user/hooks'
import { getBlockExploreLink, getBlockExploreName } from 'utils'
import truncateHash from 'utils/truncateHash'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useCombinedInactiveList } from 'state/lists/hooks'
import { CurrencyLogo, ListLogo } from 'components/Logo'
import { useTranslation } from '@noahswap/localization'
import styled from 'styled-components'

interface ImportProps {
  tokens: Token[]
  handleCurrencySelect?: (currency: Currency) => void
}

const ImportTop = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  margin-bottom: 40px;
  /* padding-top: 10px; */
  /* margin-top: -40px; */
  img {
    display: block;
    margin-bottom: 15px;
  }
  .tip-p {
    width: 100%;
    text-align: center;
    line-height: 20px;
    color: #000000;
    font-weight: 500;
  }
`
const DetailWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #f6f6f7;
  font-size: 14px;
  padding-bottom: 50px;
  border-radius: 10px;
`

function ImportToken({ tokens, handleCurrencySelect }: ImportProps) {
  const { chainId } = useActiveWeb3React()

  const { t } = useTranslation()

  const [confirmed, setConfirmed] = useState(false)

  const addToken = useAddUserToken()

  // use for showing import source on inactive tokens
  const inactiveTokenList = useCombinedInactiveList()

  return (
    <AutoColumn gap="lg">
      <ImportTop>
        {/* <img src="/images/small-img/importImg.png" width={50} alt="" /> */}
        <p className="tip-p">{t('noah33')}</p>
      </ImportTop>
      {tokens.map((token) => {
        const list = chainId && inactiveTokenList?.[chainId]?.[token.address]?.list
        const address = token.address ? `${truncateHash(token.address)}` : null
        return (
          <DetailWrap>
            <CurrencyLogo size="60px" style={{ marginTop: '-30px' }} />
            <Flex alignItems="center">
              <Text>{token.symbol}</Text>
            </Flex>
            {/* 项目方 */}
            <Text color="#999" fontSize={14}>
              {token.name}
            </Text>
            <Text mr="4px" mt="25px" fontSize={14}>
              {token.address}
            </Text>
          </DetailWrap>
        )
      })}
      <Button
        onClick={() => {
          tokens.forEach((token) => addToken(token))
          if (handleCurrencySelect) {
            handleCurrencySelect(tokens[0])
          }
        }}
      >
        {t('noah27')}
      </Button>
    </AutoColumn>
  )
}

export default ImportToken
