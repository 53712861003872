/* eslint-disable */
import { useCallback, useState, useRef, useEffect } from 'react'
import { Currency, Token } from '@noahswap/sdk'
import {
  ModalContainer,
  ModalHeader,
  ModalTitle,
  ModalBackButton,
  ModalCloseButton,
  ModalBody,
  InjectedModalProps,
  Heading,
  Button,
  useModal,
  useMatchBreakpoints,
  MODAL_SWIPE_TO_CLOSE_VELOCITY,
} from '@noahswap/uikit'
import styled from 'styled-components'
import { usePreviousValue } from '@noahswap/hooks'
import { TokenList } from '@uniswap/token-lists'
import { useTranslation } from '@noahswap/localization'
import CurrencySearch from './CurrencySearch'
import ImportToken from './ImportToken'
import Manage from './Manage'
import ImportList from './ImportList'
import { CurrencyModalView } from './types'
// import TipsModal from './TipsModal'

const Footer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.backgroundAlt};
  text-align: center;
`
const StyledModalContainer = styled(ModalContainer)`
  width: 100%;
  min-width: 320px;
  max-width: 420px !important;
  min-height: calc(var(--vh, 1vh) * 90);
  ${({ theme }) => theme.mediaQueries.md} {
    min-height: auto;
  }
`

const StyledModalBody = styled(ModalBody)<{ manage?: boolean }>`
  padding: ${({ manage }) => (manage ? '0 24px 24px' : '24px')};
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

const ManageFooter = styled.div`
  background: RGBA(228, 238, 251, 0.72);
  position: absolute;
  bottom: 0;
  height: 60px;
  line-height: 60px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #000;
`
const symbolArray = [
  '0xfA9343C3897324496A05fC75abeD6bAC29f8A40f',
  '0x922D641a426DcFFaeF11680e5358F34d97d112E1',
  '0xeFAeeE334F0Fd1712f9a8cc375f427D9Cdd40d73',
  '0x765277EebeCA2e31912C9946eAe1021199B39C61',
  '0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b',
  '0x461d52769884ca6235B685EF2040F47d30C94EB5',
]
export interface CurrencySearchModalProps extends InjectedModalProps {
  selectedCurrency?: Currency | null
  onCurrencySelect: (currency: Currency) => void
  otherSelectedCurrency?: Currency | null
  showCommonBases?: boolean
  commonBasesType?: string
  listData?: any
}

export default function CurrencySearchModal({
  onDismiss = () => null,
  onCurrencySelect,
  selectedCurrency,
  otherSelectedCurrency,
  showCommonBases = true,
  commonBasesType,
  listData,
}: CurrencySearchModalProps) {
  const [modalView, setModalView] = useState<CurrencyModalView>(CurrencyModalView.search)

  const handleCurrencySelect = useCallback(
    (currency: Currency) => {
      onDismiss?.()
      // if (symbolArray.includes(currency.address)) {
      //   openModalWithItem(currency)
      //   return false
      // }
      onCurrencySelect(currency)
    },
    [onDismiss, onCurrencySelect],
  )

  // for token import view
  const prevView = usePreviousValue(modalView)

  // used for import token flow
  const [importToken, setImportToken] = useState<Token | undefined>()

  // used for import list
  const [importList, setImportList] = useState<TokenList | undefined>()
  const [listURL, setListUrl] = useState<string | undefined>()
  const [symobj, setSymobj] = useState(null) // 需要传递的item, 当前

  const { t } = useTranslation()

  const config = {
    [CurrencyModalView.search]: { title: t('noah23'), onBack: undefined },
    [CurrencyModalView.manage]: { title: t('noah28'), onBack: () => setModalView(CurrencyModalView.search) },
    [CurrencyModalView.importToken]: {
      title: t('noah32'),
      onBack: () =>
        setModalView(prevView && prevView !== CurrencyModalView.importToken ? prevView : CurrencyModalView.search),
    },
    [CurrencyModalView.importList]: { title: t('noah32'), onBack: () => setModalView(CurrencyModalView.search) },
  }
  // const handleGoSelect = async (data) => {
  //   // console.log('选择币种啊', data)
  //   onCurrencySelect(data)
  // }

  // const [onPresentTipsModal] = useModal(
  //   <TipsModal handleGoSelect={handleGoSelect} symbolData={symobj} />,
  //   true,
  //   true,
  //   'tipsModal',
  // )

  const { isMobile } = useMatchBreakpoints()
  const wrapperRef = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState(undefined)
  useEffect(() => {
    if (!wrapperRef.current) return
    setHeight(wrapperRef.current.offsetHeight - 330)
  }, [])

  // useEffect(() => {
  //   if (symobj !== null) {
  //     onPresentTipsModal()
  //   }
  // }, [symobj])

  // const openModalWithItem = (item) => {
  //   setSymobj(item)
  //   // onPresentTipsModal() // 提示弹窗
  // }

  return (
    <StyledModalContainer
      drag={isMobile ? 'y' : false}
      dragConstraints={{ top: 0, bottom: 600 }}
      dragElastic={{ top: 0 }}
      dragSnapToOrigin
      onDragStart={() => {
        if (wrapperRef.current) wrapperRef.current.style.animation = 'none'
      }}
      // @ts-ignore
      onDragEnd={(e, info) => {
        if (info.velocity.y > MODAL_SWIPE_TO_CLOSE_VELOCITY && onDismiss) onDismiss()
      }}
      ref={wrapperRef}
    >
      <ModalHeader>
        <ModalTitle>
          {config[modalView].onBack && <ModalBackButton onBack={config[modalView].onBack} />}
          <Heading>{config[modalView].title}</Heading>
        </ModalTitle>
        <ModalCloseButton onDismiss={onDismiss} />
      </ModalHeader>
      <StyledModalBody
        manage={
          modalView === CurrencyModalView.search ||
          modalView === CurrencyModalView.manage ||
          modalView === CurrencyModalView.importToken
        }
      >
        {modalView === CurrencyModalView.search ? (
          <CurrencySearch
            onCurrencySelect={handleCurrencySelect}
            selectedCurrency={selectedCurrency}
            otherSelectedCurrency={otherSelectedCurrency}
            showCommonBases={showCommonBases}
            commonBasesType={commonBasesType}
            showImportView={() => setModalView(CurrencyModalView.importToken)}
            setImportToken={setImportToken}
            height={height}
            listData={listData}
          />
        ) : modalView === CurrencyModalView.importToken && importToken ? (
          <ImportToken tokens={[importToken]} handleCurrencySelect={handleCurrencySelect} />
        ) : modalView === CurrencyModalView.importList && importList && listURL ? (
          <ImportList list={importList} listURL={listURL} onImport={() => setModalView(CurrencyModalView.manage)} />
        ) : modalView === CurrencyModalView.manage ? (
          <Manage
            setModalView={setModalView}
            setImportToken={setImportToken}
            setImportList={setImportList}
            setListUrl={setListUrl}
            listData={listData}
          />
        ) : (
          ''
        )}
        {modalView === CurrencyModalView.search && (
          <Footer>
            <Button
              variant="primary"
              width="100%"
              onClick={() => setModalView(CurrencyModalView.manage)}
              className="list-token-manage-button"
            >
              {t('noah28')}
            </Button>
          </Footer>
        )}
      </StyledModalBody>
      {modalView === CurrencyModalView.manage ? <ManageFooter>{t('noah31')}</ManageFooter> : ''}
    </StyledModalContainer>
  )
}
